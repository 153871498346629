// Dependencies

import { configureStore } from '@reduxjs/toolkit';

// Components and functions

import formSpinner from './formSpinner/formSpinner.slices';
import messages from './messages/messages.slice';
import user from './user/user.slices';

const store = configureStore({
  reducer: { formSpinner, messages, user },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
