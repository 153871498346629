// Dependencies

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Components and functions

import { IUserRedux } from '../../interfaces/user.interfaces';
import { RootState } from '../store';

// State

const initialState: IUserRedux = {
  authToken: null,
  isAuthenticated: false,
  isAuthorized: false,
  level: null,
  user: null,
};

// Slice

const UserSlice = createSlice({
  name: 'User',
  initialState: initialState,
  reducers: {
    userClear: (state) => {
      return (state = initialState);
    },
    userLoad: (state, action: PayloadAction<IUserRedux>) => {
      return (state = action.payload);
    },
  },
});

// Exports

export const { userClear, userLoad } = UserSlice.actions;

export const getUserSelector = (state: RootState) => state.user;

export default UserSlice.reducer;
