// Material UI

import { createTheme, responsiveFontSizes } from '@mui/material';

const _blue = '#0C74B9';
const _orange = '#FFBA60';

let theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: _blue,
    },
    secondary: {
      main: _orange,
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
});

theme = responsiveFontSizes(theme);

export default theme;
