// Dependencies

import store from '../redux/store';
import { toast } from 'react-toastify';

// Components and functions

import { IUserGetter } from '../interfaces/user.interfaces';
import { formSpinnerActive } from '../redux/formSpinner/formSpinner.slices';
import { routeLevels } from '../routes/Routes';
import { userLoad } from '../redux/user/user.slices';

const signInUser = (user: IUserGetter | null, token: string) => {
  let authorized: boolean = false;
  let level: number | null = null;
  if (user) {
    authorized = user.active ? true : false;
    level = user.active ? routeLevels.portalAdmin : null;
  }
  store.dispatch(
    userLoad({
      authToken: token,
      isAuthorized: authorized,
      isAuthenticated: true,
      level: level,
      user: user,
    })
  );
};

export const getCurrentUser = async (authToken: string) => {
  try {
    const user: IUserGetter = {
      id: 1,
      email: 'test@test.com',
      name: 'Test User',
      sysAdmin: true,
      active: true,
      createdAt: null,
      updatedAt: null,
    };
    signInUser(user, authToken);
    return true;
  } catch (error) {
    console.log('error experienced');
  }
  toast.error('We experienced an error getting the user');
  store.dispatch(formSpinnerActive(false));
  return false;
};
