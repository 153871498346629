// Dependencies

import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Components and functions

import Error from './pages/error/Error';
import RouteGuard from './routes/RouteGuard';
import routes, { routeNames } from './routes/Routes';
import { auth } from './firebase/firebase';
import { getCurrentUser } from './axios/users';
import { getUserSelector } from './redux/user/user.slices';
import { onAuthStateChanged } from 'firebase/auth';

// Props

interface IAppProps {}

export const App: React.FC<IAppProps> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector(getUserSelector);

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        console.log('Firebase user detected', isLoading, firebaseUser);
        const idTokenResult = await firebaseUser.getIdTokenResult();
        await getCurrentUser(idTokenResult.token);
      } else {
        console.log('No Firebase user detected', isLoading);
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading) return null;

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <RouteGuard route={route} user={user}>
              {route.component}
            </RouteGuard>
          }
        />
      ))}
      <Route path={routeNames.root} element={user.isAuthenticated ? <Navigate to={routeNames.dashboard} /> : <Navigate to={routeNames.signin} />} />
      <Route path='*' element={<Error />} />
    </Routes>
  );
};

export default App;
