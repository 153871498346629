// Dependencies

import styled from 'styled-components';

export const Wrapper = styled.div`
  .copyright-copyright {
    color: #d4d4d4;
  }

  .copyright-divider {
    color: #c7c7c7;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .copyright-link {
    color: #c2d9ff;
  }

  .copyright-grid {
    color: #8f8f8f;
    text-align: center;
  }

  .copyright-title {
    color: #ffffff;
  }
`;
