// Dependencies

import axios from 'axios';
import { FirebaseError } from '@firebase/util';

export const handleError = (error: any) => {
  let errorMessage = '';
  if (error instanceof FirebaseError) {
    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage = 'This email is already in use.';
        break;
      case 'auth/popup-closed-by-user':
        errorMessage = 'The sign in window was closed by the user.';
        break;
      case 'auth/too-many-requests':
        errorMessage = 'There have been too many attempts to sign-in. You will need to wait 5 minutes.';
        break;
      case 'auth/user-disabled':
        errorMessage = 'This user has been disabled.';
        break;
      case 'auth/user-not-found':
        errorMessage = 'This user has not been found.';
        break;
      case 'auth/weak-password':
        errorMessage = 'Your password is to weak.';
        break;
      case 'auth/wrong-password':
        errorMessage = 'Can not sign in with these credentials.';
        break;
      default:
        errorMessage = 'Firebase error - ' + error.message;
    }
  } else if (axios.isAxiosError(error)) {
    errorMessage = error.response?.status + ' - ' + error.response?.statusText;
  } else {
    console.log('unknown error', error);
    errorMessage = 'Unknown error experienced';
  }
  return errorMessage;
};
