// Dependencies

import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI

import { Box, Button, Grid, Typography } from '@mui/material';

// Components and functions

import FormInputText from '../../common/form/FormInputText';
import Public from '../../pages/public/Public';
import ThemeToggle from '../../styling/ThemeToggle';
import { auth } from '../../firebase/firebase';
import { formSpinnerActive } from '../../redux/formSpinner/formSpinner.slices';
import { routeNames } from '../../routes/Routes';

// Props

interface IForgotProps {}

// Enums and Constants

interface IFormInputs {
  email: string;
}

// Form Validation Schema

const schema = yup.object().shape({
  email: yup.string().email('must be a legal email address').required('email is required'),
});

const Forgot: React.FC<IForgotProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit, reset } = useForm<IFormInputs>({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    let errorMessage = '';
    dispatch(formSpinnerActive(true));
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        dispatch(formSpinnerActive(false));
        navigate('/');
        toast.success('An email will be sent with the reset link');
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/user-not-found':
            errorMessage = 'This user has not been found.';
            break;
          default:
            errorMessage = error.message;
        }
        toast.error(errorMessage);
        dispatch(formSpinnerActive(false));
      });
  });

  return (
    <Public>
      <Box className={'component-paper'}>
        <Box className={'component-logo'} />
        <Typography className={'component-caption'} component='h1' variant='h5'>
          forgot password?
        </Typography>
        <form className={'component-form'} id='signIn' noValidate onSubmit={onSubmit}>
          <FormInputText control={control} label='email' name='email' />
          <Button className={'component-button-reset'} onClick={() => reset()}>
            reset entry
          </Button>
          <Button className={'component-button-signin'} color='primary' disableElevation fullWidth type='submit' variant='contained'>
            submit
          </Button>
          <Grid container>
            <Grid className={'component-link'} component={Link} item to={routeNames.signin} xs>
              sign in
            </Grid>
          </Grid>
        </form>
        <ThemeToggle />
      </Box>
    </Public>
  );
};

export default Forgot;
