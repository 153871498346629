// Dependencies

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// Material UI

import { CssBaseline } from '@mui/material';

// Components and functions

import App from './App';
import FormSpinner from './common/form/formSpinner/FormSpinner';
import store from './redux/store';

// Styles

import 'react-toastify/dist/ReactToastify.css';
import ThemeProvider from './styling/ThemeProvider';

const rootEl = document.getElementById('root');

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <CssBaseline />
          <FormSpinner />
          <ToastContainer theme='colored' />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>,
    rootEl
  );
}

if (module.hot) {
  module.hot.accept('./App', function () {
    setTimeout(render);
  });
}

render();
