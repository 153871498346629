// Components and functions

import About from '../components/private/About';
import Customer from '../components/private/Customer';
import Dashboard from '../components/private/Dashboard';
import Forgot from '../components/public/Forgot';
import Inventory from '../components/private/Inventory';
import Register from '../components/public/Register';
import Reset from '../components/public/Reset';
import SignIn from '../components/public/SignIn';
import { IRoute } from '../interfaces/route.interfaces';

export enum routeLevels {
  'none' = 0,
  'all' = 1,
  'portalAdmin' = 2,
  'dealerAdmin' = 3,
  'dealerUser' = 4,
  'clientUser' = 5,
}

export enum routeNames {
  'about' = '/about',
  'customer' = '/customer',
  'dashboard' = '/dashboard',
  'forgot' = '/forgot',
  'inventory' = '/inventory',
  'register' = '/register',
  'reset' = '/reset',
  'root' = '/',
  'signin' = '/signin',
}

const routes: IRoute[] = [
  {
    component: <About />,
    name: 'About',
    path: routeNames.about,
    protected: {
      levels: [routeLevels.all],
      isAuthorizedOnly: false,
      isProtected: true,
    },
  },
  {
    component: <Customer />,
    name: 'Customer',
    path: routeNames.customer,
    protected: {
      levels: [routeLevels.none],
      isAuthorizedOnly: false,
      isProtected: true,
    },
  },
  {
    component: <Dashboard />,
    name: 'Dashboard',
    path: routeNames.dashboard,
    protected: {
      levels: [routeLevels.all],
      isAuthorizedOnly: false,
      isProtected: true,
    },
  },
  {
    component: <Inventory />,
    name: 'Inventory',
    path: routeNames.inventory,
    protected: {
      levels: [routeLevels.none],
      isAuthorizedOnly: false,
      isProtected: true,
    },
  },
  {
    component: <Forgot />,
    name: 'Forgot',
    path: routeNames.forgot,
    protected: {
      levels: [routeLevels.none],
      isAuthorizedOnly: false,
      isProtected: false,
    },
  },
  {
    component: <Register />,
    name: 'Register',
    path: routeNames.register,
    protected: {
      levels: [routeLevels.none],
      isAuthorizedOnly: false,
      isProtected: false,
    },
  },
  {
    component: <Reset />,
    name: 'Reset',
    path: routeNames.reset,
    protected: {
      levels: [routeLevels.none],
      isAuthorizedOnly: false,
      isProtected: false,
    },
  },
  {
    component: <SignIn />,
    name: 'Sign in',
    path: routeNames.signin,
    protected: {
      levels: [routeLevels.none],
      isAuthorizedOnly: false,
      isProtected: false,
    },
  },
];

export default routes;
