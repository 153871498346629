// Dependencies

import { createSlice } from '@reduxjs/toolkit';

// Components and functions

import { IMessageRedux } from '../../interfaces/message.interfaces';
import { RootState } from '../store';

// State

const initialState: IMessageRedux = {
  loading: false,
  messages: [],
  total: 0,
};

// Slice

const messagesSlice = createSlice({
  name: 'messages',
  initialState: initialState,
  reducers: {
    messageTotal: (state) => {
      return { ...state };
    },
  },
});

// Exports

export const { messageTotal } = messagesSlice.actions;

export const getMessagesSelector = (state: RootState) => state.messages;

export default messagesSlice.reducer;
