// Material UI

import { Theme } from '@mui/material/styles';

// Components and functions

import lightTheme from './light';
import darkTheme from './dark';

// Enums and constants

export const DARK_THEME = 'darkTheme';
export const LIGHT_THEME = 'lightTheme';
export const LOCAL_STORAGE_THEME = 'ts-auto-it-theme';

const themeMap: { [key: string]: Theme } = {
  lightTheme,
  darkTheme,
};

export function getThemeByName(theme: string): Theme {
  return themeMap[theme];
}
