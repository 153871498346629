// Material UI

import { Typography } from '@mui/material';

// Components and functions

import Private from '../../pages/private/Private';

// Props

interface IInventoryProps {}

const Inventory: React.FC<IInventoryProps> = () => {
  return (
    <Private>
      <Typography className='component-title' component='div' variant='h5'>
        Inventory
      </Typography>
    </Private>
  );
};

export default Inventory;
