// Material UI

import { Typography } from '@mui/material';

// Components and functions

import Private from '../../pages/private/Private';

// Props

interface ICustomerProps {}

const Customer: React.FC<ICustomerProps> = () => {
  return (
    <Private>
      <Typography className='component-title' component='div' variant='h5'>
        Customer
      </Typography>
    </Private>
  );
};

export default Customer;
