// Dependencies

import React, { createContext, useState } from 'react';

// Material UI

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

// Components and functions

import { getThemeByName, LIGHT_THEME, LOCAL_STORAGE_THEME } from './base';

// Props

interface IThemeProviderProps {}

export const ThemeContext = createContext((themeName: string): void => {});

const ThemeProvider: React.FC<IThemeProviderProps> = (props) => {
  // We start off by getting the current theme. If there is none we'll set it as light
  const currentTheme = localStorage.getItem(LOCAL_STORAGE_THEME) || LIGHT_THEME;

  // We use local state to set the theme name to the current theme
  const [themeName, _setThemeName] = useState(currentTheme);

  // We now get the them by the theme name
  const theme = getThemeByName(themeName);

  // This is the tricky bit, We wrap the local setThemeName so, if the name changes in the
  // context it will update local storage and then reset the theme name
  const setThemeName = (name: string) => {
    localStorage.setItem(LOCAL_STORAGE_THEME, name);
    _setThemeName(name);
  };

  return (
    <ThemeContext.Provider value={setThemeName}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
