// Dependencies

import { Navigate } from 'react-router-dom';

// Components and functions

import { IRoute } from '../interfaces/route.interfaces';
import { IUserRedux } from '../interfaces/user.interfaces';
import { routeNames } from './Routes';

// Props

interface IRouteGuardProps {
  children: JSX.Element;
  route: IRoute;
  user: IUserRedux;
}

const RouteGuard: React.FC<IRouteGuardProps> = ({ children, route, user }) => {
  // Handle an unauthenticated user.
  if (!user.isAuthenticated) {
    // Make sure the route is unprotected.
    return !route.protected.isProtected ? children : <Navigate to={routeNames.root} />;
  }

  // Handle an authenticated user. Make sure the route is protected.
  return route.protected.isProtected ? children : <Navigate to={routeNames.root} />;
};

export default RouteGuard;
