// Dependencies

import { useContext, useState } from 'react';

// Material UI

import { FormControlLabel, Switch } from '@mui/material';

// Components and functions

import { DARK_THEME, LIGHT_THEME, LOCAL_STORAGE_THEME } from './base';
import { ThemeContext } from './ThemeProvider';

// Props

interface IThemeToggleProps {}

const ThemeToggle: React.FC<IThemeToggleProps> = () => {
  const setThemeName = useContext(ThemeContext);

  const currentTheme = localStorage.getItem(LOCAL_STORAGE_THEME) || LIGHT_THEME;
  const [switchState, setSwitchState] = useState(currentTheme === DARK_THEME);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setThemeName(DARK_THEME);
    } else {
      setThemeName(LIGHT_THEME);
    }
    setSwitchState(e.target.checked);
  };

  return <FormControlLabel control={<Switch onChange={(e) => handleChange(e)} checked={switchState} />} label={!switchState ? 'Light' : 'Dark'} />;
};

export default ThemeToggle;
