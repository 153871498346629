// Dependencies

import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI

import { Box, Button, Grid, Typography } from '@mui/material';

// Components and functions

import FormInputText from '../../common/form/FormInputText';
import Public from '../../pages/public/Public';
import ThemeToggle from '../../styling/ThemeToggle';
import { auth } from '../../firebase/firebase';
import { formSpinnerActive } from '../../redux/formSpinner/formSpinner.slices';
import { routeNames } from '../../routes/Routes';
import { useDispatch } from 'react-redux';

// Props

interface IRegisterProps {}

// Enums and Constants

interface IFormInputs {
  email: string;
  password: string;
  confirmPassword: string;
}

// Form Validation Schema

const schema = yup.object().shape({
  email: yup.string().email('must be a legal email address').required('email is required'),
  password: yup.string().required('password is required'),
  confirmPassword: yup
    .string()
    .required('passwords must match')
    .oneOf([yup.ref('password'), null], 'passwords must match'),
});

const Register: React.FC<IRegisterProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit, reset } = useForm<IFormInputs>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    let errorMessage = '';
    dispatch(formSpinnerActive(true));
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(() => {
        dispatch(formSpinnerActive(false));
        navigate('/');
        toast.success('Your registration has been successful.');
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage = 'This email is already in use.';
            break;
          case 'auth/weak-password':
            errorMessage = 'Your password is to weak.';
            break;
          default:
            errorMessage = error.message;
        }
        toast.error(errorMessage);
        dispatch(formSpinnerActive(false));
      });
  });

  return (
    <Public>
      <Box className={'component-paper'}>
        <Box className={'component-logo'} />
        <Typography className={'component-caption'} component='h1' variant='h5'>
          register
        </Typography>
        <form className={'component-form'} id='signIn' noValidate onSubmit={onSubmit}>
          <FormInputText control={control} label='email' name='email' />
          <FormInputText control={control} inputProps={{ type: 'password' }} label='password' name='password' />
          <FormInputText control={control} inputProps={{ type: 'password' }} label='confirm password' name='confirmPassword' />
          <Button className={'component-button-reset'} onClick={() => reset()}>
            reset entry
          </Button>
          <Button className={'component-button-signin'} color='primary' disableElevation fullWidth type='submit' variant='contained'>
            register
          </Button>
          <Grid container>
            <Grid className={'component-link'} component={Link} item to={routeNames.signin} xs>
              sign in
            </Grid>
          </Grid>
        </form>
        <ThemeToggle />
      </Box>
    </Public>
  );
};

export default Register;
