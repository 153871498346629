// Dependencies

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Material UI

import NotificationsIcon from '@mui/icons-material/Notifications';
import { Tooltip, IconButton, Badge } from '@mui/material';

// Compnents and functions

import { getMessagesSelector } from '../../../redux/messages/messages.slice';

// Props

interface IMessagesProps {}

export const handleMessageClick = (total: number) => {
  if (total === 0) {
    toast.error('You have no messages');
  } else {
    toast.success(total === 1 ? 'You have 1 message' : `You have ${total} messages`);
  }
};

const Messages: React.FC<IMessagesProps> = () => {
  const messages = useSelector(getMessagesSelector);

  return messages.total > 0 ? (
    <Tooltip title='Messages'>
      <IconButton
        aria-label='messages'
        color='inherit'
        disabled={messages.total === 0 ? true : false}
        onClick={() => handleMessageClick(messages.total)}
      >
        <Badge badgeContent={messages.total} color='secondary'>
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton
      aria-label='messages'
      color='inherit'
      disabled={messages.total === 0 ? true : false}
      onClick={() => handleMessageClick(messages.total)}
    >
      <NotificationsIcon />
    </IconButton>
  );
};

export default Messages;
