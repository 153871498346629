// Dependencies

import * as yup from 'yup';
import { FaGoogle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI

import { Box, Button, Grid, Typography } from '@mui/material';

// Components and function

import FormInputText from '../../common/form/FormInputText';
import Public from '../../pages/public/Public';
import ThemeToggle from '../../styling/ThemeToggle';
import { auth, Providers } from '../../firebase/firebase';
import { formSpinnerActive } from '../../redux/formSpinner/formSpinner.slices';
import { getCurrentUser } from '../../axios/users';
import { handleError } from '../../functions/messages';
import { routeNames } from '../../routes/Routes';

// Props

interface ISignInProps {}

// Enums and Constants

interface IFormInputs {
  email: string;
  password: string;
}

// Form Validation Schema

const schema = yup.object().shape({
  email: yup.string().email('must be a legal email address').required('email is required'),
  password: yup.string().required('password is required'),
});

const SignIn: React.FC<ISignInProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit, reset } = useForm<IFormInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    dispatch(formSpinnerActive(true));
    try {
      const result = await signInWithEmailAndPassword(auth, data.email, data.password);
      const { user } = result;
      const idTokenResult = await user.getIdTokenResult();
      await getCurrentUser(idTokenResult.token);
      dispatch(formSpinnerActive(false));
      navigate('/');
    } catch (error) {
      toast.error(handleError(error));
      dispatch(formSpinnerActive(false));
    }
  });

  const onHandleGoogleSignIn = async () => {
    dispatch(formSpinnerActive(true));
    try {
      const result = await signInWithPopup(auth, Providers.google);
      const { user } = result;
      const idTokenResult = await user.getIdTokenResult();
      await getCurrentUser(idTokenResult.token);
      dispatch(formSpinnerActive(false));
      navigate('/');
    } catch (error) {
      toast.error(handleError(error));
      dispatch(formSpinnerActive(false));
    }
  };

  return (
    <Public>
      <Box className={'component-paper'}>
        <Box className={'component-logo'} />
        <Typography className={'component-caption'} component='h1' variant='h5'>
          sign in
        </Typography>
        <form className={'component-form'} id='signIn' noValidate onSubmit={onSubmit}>
          <FormInputText control={control} label='email' name='email' />
          <FormInputText control={control} inputProps={{ type: 'password' }} label='password' name='password' />
          <Button className={'component-button-reset'} onClick={() => reset()}>
            reset entry
          </Button>
          <Button className={'component-button-signin'} color='primary' disableElevation fullWidth type='submit' variant='contained'>
            sign in
          </Button>
          <Grid container>
            <Grid className={'component-link'} component={Link} item to={routeNames.forgot} xs>
              forgot password?
            </Grid>
            <Grid className={'component-link'} component={Link} item to={routeNames.register}>
              register
            </Grid>
          </Grid>
          <Button
            className={'component-button-signin'}
            color='secondary'
            disableElevation
            fullWidth
            onClick={() => onHandleGoogleSignIn()}
            startIcon={<FaGoogle />}
            variant='contained'
          >
            google sign in
          </Button>
        </form>
        <ThemeToggle />
      </Box>
    </Public>
  );
};

export default SignIn;
