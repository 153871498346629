// Dependencies

import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

// Material UI

// Components and functions

import { getFormSpinnerSelector } from '../../../redux/formSpinner/formSpinner.slices';

// Styles

import { Wrapper } from './FormSpinner.styles';

// Props

interface IFormSpinnerProps {}

const FormSpinner: React.FC<IFormSpinnerProps> = () => {
  const formSpinner = useSelector(getFormSpinnerSelector);
  return (
    <Wrapper>
      <Backdrop className={'formspinner-backdrop'} open={formSpinner.isActive}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Wrapper>
  );
};

export default FormSpinner;
