// Dependencies

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

// Material UI

import { Button, Divider, Grid, Typography } from '@mui/material';

// Components and functions

import ModalAlert from '../../common/modal/ModalAlert';
import Private from '../../pages/private/Private';
import { formSpinnerActive } from '../../redux/formSpinner/formSpinner.slices';

// Styles

import { AboutAlertButton } from '../../pages/private/Private.styles';

// Props

interface IAboutProps {}

const About: React.FC<IAboutProps> = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleModalClosed = (source: string) => {
    console.log(source);
    setModalOpen(false);
  };

  const displayModal = () => (
    <ModalAlert
      buttonCaption='close'
      content='This is an alert modal ceated using Material UI'
      onClose={handleModalClosed}
      open={modalOpen}
      title='Material UI Alert Modal'
    />
  );

  const handleSpinner = () => {
    dispatch(formSpinnerActive(true));
    setTimeout(() => {
      dispatch(formSpinnerActive(false));
    }, 3000);
  };

  return (
    <Private>
      <Grid item xs={12}>
        <Typography className='component-title' component='div' variant='h5'>
          About
        </Typography>
        <Typography className={'component-caption'} variant='body1'>
          Welcome to the Auto-IT Portal Control System (PCS). This site provides AIT admin, dealer Admin, dealer users and dealer customers access to
          a number of features and services via the AIT Cloud portal controller and then to AIT Cloud products and services.
        </Typography>
        <Divider className={'component-divider'} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={'component-caption'} variant='body1'>
          Click the button to cause the formSpinner to activate for three seconds. It will clear when finished.
        </Typography>
        <Button color='primary' onClick={handleSpinner} variant='contained'>
          Create Form Spinner
        </Button>
        <Divider className={'component-divider'} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={'component-caption'} variant='body1'>
          Click the button to open the alert modal.
        </Typography>
        <Button color='primary' onClick={() => setModalOpen(true)} variant='contained'>
          Display Modal
        </Button>
        <Divider className={'component-divider'} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={'component-caption'} variant='body1'>
          Click the appropriate button to open a toast.
        </Typography>
        <AboutAlertButton color='success' onClick={() => toast.success('this is a simple success toast')} variant='contained'>
          Open Success Toast
        </AboutAlertButton>
        <AboutAlertButton color='error' onClick={() => toast.error('this is a simple error toast')} variant='contained'>
          Open Error Toast
        </AboutAlertButton>
        <AboutAlertButton color='warning' onClick={() => toast.warning('this is a simple warning toast')} variant='contained'>
          Open Warning Toast
        </AboutAlertButton>
      </Grid>
      {displayModal()}
    </Private>
  );
};

export default About;
