// Dependencies

import clsx from 'clsx';
import { useState } from 'react';

// Material UI

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Drawer, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';

// Components and functions

import DrawerItems from './helpers/DrawerItems';
import DropDownItems from './helpers/DropDownItems';
import Messages from './helpers/Messages';
import ThemeToggle from '../../styling/ThemeToggle';

// Styles

import { Wrapper } from './Private.styles';

// Props

interface IPrivateProps {}

const Private: React.FC<IPrivateProps> = ({ children }) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [smallOpen, setSmallOpen] = useState(false);
  const [largeOpen, setLargeOpen] = useState(true);

  const handleSmallOpenToggle = () => {
    setSmallOpen(!smallOpen);
  };

  const handleLargeOpenToggle = () => {
    setLargeOpen(!largeOpen);
  };

  return (
    <Wrapper>
      <Box className={'page-root'}>
        <AppBar className={'page-appbar'} elevation={0} position='fixed'>
          <Toolbar className={'page-toolbar'}>
            <IconButton
              aria-label='Open drawer'
              className={'page-menu-button'}
              color='inherit'
              edge='start'
              onClick={isSmallScreen ? handleSmallOpenToggle : handleLargeOpenToggle}
            >
              {isSmallScreen ? <MenuIcon /> : largeOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Typography sx={{ mr: '1rem' }} noWrap variant='h6'>
              Dashboard
            </Typography>
            <Box className={'page-grow'} />
            <Box className={'page-menu-section'}>
              <ThemeToggle />
            </Box>
            <Box className={'page-menu-section'}>
              <Messages />
            </Box>
            <DropDownItems isSmallScreen={isSmallScreen} />
          </Toolbar>
        </AppBar>
        <nav className={'page-drawer'}>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Drawer
              classes={{
                paper: 'page-drawer-paper',
              }}
              onClose={handleSmallOpenToggle}
              open={smallOpen}
              variant='persistent'
            >
              <DrawerItems />
            </Drawer>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Drawer
              classes={{
                paper: 'page-drawer-paper',
              }}
              open={largeOpen}
              variant='persistent'
            >
              <DrawerItems />
            </Drawer>
          </Box>
        </nav>
        <main
          className={clsx('page-content', {
            'page-content-shift': largeOpen || isSmallScreen,
          })}
        >
          <Box className={'page-content-appbar'} />
          <Box>{children}</Box>
        </main>
      </Box>
    </Wrapper>
  );
};

export default Private;
