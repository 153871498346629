// Dependencies

import { Controller } from 'react-hook-form';

// Material UI

import { InputBaseComponentProps, TextField } from '@mui/material';

// Props

interface IFormInputTextProps {
  control?: any;
  inputProps?: InputBaseComponentProps;
  label: string;
  name: string;
}

const FormInputText: React.FC<IFormInputTextProps> = ({ control, inputProps, label, name }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          className={'form-input-text'}
          error={!!error}
          fullWidth
          helperText={error ? error.message : null}
          inputProps={inputProps}
          label={label}
          onChange={onChange}
          value={value}
          variant='outlined'
        />
      )}
    />
  );
};

export default FormInputText;
