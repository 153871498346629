// Material UI

import { Box, Grid, Hidden, Paper } from '@mui/material';

// Components and functions

import Copyright from '../../common/copyright/Copyright';

// Styles

import { Wrapper } from './Public.styles';

// Props

interface IPublicProps {}

const Public: React.FC<IPublicProps> = (props) => {
  const { children } = props;
  return (
    <Wrapper>
      <Grid className={'page-grid'} container component='main'>
        <Grid item className={'page-image'} xs={false} sm={5} md={6} lg={7} xl={9}>
          <Hidden smDown>
            <Box className={'page-footer'}>
              <Hidden mdDown>
                <Copyright />
              </Hidden>
            </Box>
          </Hidden>
        </Grid>
        <Grid item component={Paper} elevation={6} square xs={12} sm={7} md={6} lg={5} xl={3}>
          {children}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Public;
