// Dependencies

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';

// Material UI

import DescriptionIcon from '@mui/icons-material/Description';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import PrintIcon from '@mui/icons-material/Print';
import { Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Theme, useTheme } from '@mui/material';

// Components and functions

import { IUserRedux } from './../../../interfaces/user.interfaces';
import { getUserSelector } from '../../../redux/user/user.slices';
import { routeNames } from '../../../routes/Routes';

// Props

interface IDrawerItemsProps {}

// Helpers

const getItems = (user: IUserRedux, theme: Theme) => {
  let items = [];

  if (user.isAuthorized) {
    items = [
      {
        image: true,
        imageAlt: 'logo',
        imageFile:
          theme.palette.mode === 'light'
            ? `${process.env.PUBLIC_URL + '/assets/img/ap_logo.png'}`
            : `${process.env.PUBLIC_URL + '/assets/img/ap_logo_dark.png'}`,
      },
      { divider: true },
      { icon: <HomeIcon />, link: routeNames.dashboard, text: 'Dashboard' },
      { icon: <PersonIcon />, link: routeNames.customer, text: 'Customer' },
      { icon: <DescriptionIcon />, link: routeNames.inventory, text: 'Inventory' },
      { divider: true },
      { icon: <InfoIcon />, link: routeNames.about, text: 'About' },
      { divider: true },
      {
        icon: <PrintIcon />,
        text: 'Reports',
        subItems: [
          { class: 'drawer-nested', link: routeNames.customer, text: 'Customer' },
          { class: 'drawer-nested', link: routeNames.inventory, text: 'Inventory' },
        ],
      },
    ];
  } else {
    items = [
      {
        image: true,
        imageAlt: 'logo',
        imageFile:
          theme.palette.mode === 'light'
            ? `${process.env.PUBLIC_URL + '/assets/img/ap_logo.png'}`
            : `${process.env.PUBLIC_URL + '/assets/img/ap_logo_dark.png'}`,
      },
      { divider: true },
      { icon: <HomeIcon />, link: routeNames.dashboard, text: 'Dashboard' },
      { divider: true },
      { icon: <InfoIcon />, link: routeNames.about, text: 'About' },
    ];
  }
  return items;
};

const DrawerItems: React.FC<IDrawerItemsProps> = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const user = useSelector(getUserSelector);

  const items = getItems(user, theme);

  const handleClick = () => {
    setOpen(!open);
  };

  const buildMenuItem = (item: any, index: any) => {
    if (item.divider) return <Divider key={index} />;

    if (item.image) return <img alt={item.imageAlt} className={'page-drawer-image'} key={index} src={item.imageFile} />;

    if (!item.subItems)
      return (
        <ListItem button component={Link} key={index} to={item.link}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText classes={{ primary: 'page-drawer-list-item-text' }} primary={item.text} />
        </ListItem>
      );

    if (item.subItems)
      return (
        <div key={index}>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText classes={{ primary: 'page-drawer-list-item-text' }} primary={item.text} />
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {item.subItems.map((subItem: any, index: any) => (
                <ListItem button className={'page-drawer-nested'} component={Link} key={index} to={subItem.link}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText classes={{ primary: 'page-drawer-list-item-text' }} primary={subItem.text} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      );
    return;
  };

  return <>{items.map((item: any, index: any) => buildMenuItem(item, index))}</>;
};

export default DrawerItems;
