// Material UI

import { Grid, Link } from '@mui/material';

// Styles

import { Wrapper } from './Copyright.styles';

// Props

interface ICopyrightProps {}

const Copyright: React.FC<ICopyrightProps> = () => {
  return (
    <Wrapper>
      <Grid className={'copyright-grid'} container direction='row' justifyContent='center'>
        <span className={'copyright-title'}>ait cloud portal</span>
        <span className={'copyright-divider'}>|</span>
        <span className={'copyright-copyright'}>copyright {new Date().getFullYear()}</span>
        <span className={'copyright-divider'}>|</span>
        <Link className={'copyright-link'} href='http://auto-it.com/' target='_blank' underline='none'>
          auto-it
        </Link>
      </Grid>
    </Wrapper>
  );
};

export default Copyright;
