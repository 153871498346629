// Dependencies

import styled from 'styled-components';

export const Wrapper = styled.div`
  .error-button {
    border-radius: 0;
    margin-top: 1.5rem;
  }

  .error-caption {
    margin-bottom: 30px;
  }

  .error-code {
    font-size: 7rem;
    margin-bottom: 50px;
  }

  .error-message {
    color: #6b6b6b;
    left: 50%;
    position: absolute;
    top: 35%;
    transform: translate(-50%, -50%);
  }

  .error-root {
    height: 100vh;
  }
`;
