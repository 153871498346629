// Dependencies

import * as yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI

import { Box, Button, Grid, Typography } from '@mui/material';

// Components and functions

import Error from '../../pages/error/Error';
import FormInputText from '../../common/form/FormInputText';
import Public from '../../pages/public/Public';
import ThemeToggle from '../../styling/ThemeToggle';
import { auth } from '../../firebase/firebase';
import { formSpinnerActive } from '../../redux/formSpinner/formSpinner.slices';
import { routeNames } from '../../routes/Routes';

// Props

interface IResetProps {}

// Enums and Constants

interface IFormInputs {
  password: string;
  confirmPassword: string;
}

// Form Validation Schema

const schema = yup.object().shape({
  password: yup.string().required('password is required'),
  confirmPassword: yup
    .string()
    .required('passwords must match')
    .oneOf([yup.ref('password'), null], 'passwords must match'),
});

const Reset: React.FC<IResetProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const [oobCode, setOobCode] = useState<string>('');
  const [verified, setVerified] = useState<boolean>(false);
  const [verifying, setVerifying] = useState<boolean>(true);

  const { control, handleSubmit, reset } = useForm<IFormInputs>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query) {
      let oobCode = query.get('oobCode');
      if (oobCode) {
        verifyPasswordResetLink(oobCode);
      } else {
        setVerified(false);
        setVerifying(false);
      }
    } else {
      setVerified(false);
      setVerifying(false);
    }
    // eslint-disable-next-line
  }, []);

  const verifyPasswordResetLink = (oobCode: string) => {
    dispatch(formSpinnerActive(true));
    verifyPasswordResetCode(auth, oobCode)
      .then(() => {
        setOobCode(oobCode);
        setVerified(true);
        setVerifying(false);
        dispatch(formSpinnerActive(false));
      })
      .catch(() => {
        setVerified(false);
        setVerifying(false);
        dispatch(formSpinnerActive(false));
      });
  };

  const onSubmit = handleSubmit((data) => {
    let errorMessage = '';
    dispatch(formSpinnerActive(true));
    confirmPasswordReset(auth, oobCode, data.password)
      .then(() => {
        toast.success('Your password has been reset, please sign in with your new password.');
        navigate('/signin');
        dispatch(formSpinnerActive(false));
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/weak-password':
            errorMessage = 'Your password is to weak.';
            break;
          default:
            errorMessage = error.message;
        }
        toast.error(errorMessage);
        dispatch(formSpinnerActive(false));
      });
  });

  return (
    <>
      {verifying ? (
        <></>
      ) : verified ? (
        <Public>
          <Box className={'component-paper'}>
            <Box className={'component-logo'} />
            <Typography className={'component-caption'} component='h1' variant='h5'>
              reset password
            </Typography>
            <form className={'component-form'} id='signIn' noValidate onSubmit={onSubmit}>
              <FormInputText control={control} inputProps={{ type: 'password' }} label='password' name='password' />
              <FormInputText control={control} inputProps={{ type: 'password' }} label='confirm password' name='confirmPassword' />
              <Button className={'component-button-reset'} onClick={() => reset()}>
                reset entry
              </Button>
              <Button className={'component-button-signin'} color='primary' disableElevation fullWidth type='submit' variant='contained'>
                reset password
              </Button>
              <Grid container>
                <Grid className={'component-link'} component={Link} item to={routeNames.signin} xs>
                  sign in
                </Grid>
              </Grid>
            </form>
            <ThemeToggle />
          </Box>
        </Public>
      ) : (
        <Error code='401' message='The link is missing or invalid. You will need to make another reset request.' />
      )}
    </>
  );
};

export default Reset;
