// Dependencies

import { Link } from 'react-router-dom';

// Material UI

import { Box, Button, Grid, Typography } from '@mui/material';

// Components and functions

import { routeNames } from '../../routes/Routes';

// Styles

import { Wrapper } from './Error.styles';

// Props

interface IErrorProps {
  code?: string;
  message?: string;
}

const Error: React.FC<IErrorProps> = ({ code, message }) => {
  if (!code) code = '404';
  if (!message) message = 'the page you are looking for has not been found';
  return (
    <Wrapper>
      <Grid className={'error-root'} component='main' container>
        <Box className={'error-message'}>
          <Typography className={'error-code'} variant='h2'>
            {code}
          </Typography>
          <Typography className={'error-caption'} variant='body1'>
            {message}
          </Typography>
          <Button className={'error-button'} color='primary' component={Link} disableElevation to={routeNames.root} variant='contained'>
            Return to home
          </Button>
        </Box>
      </Grid>
    </Wrapper>
  );
};

export default Error;
