// Dependencies

import styled from 'styled-components';

export const Wrapper = styled.div`
  .formspinner-backdrop {
    color: #fff;
    z-index: ${(props) => props.theme.zIndex.drawer + 1};
  }
`;
