// Dependencies

import styled from 'styled-components';

export const Wrapper = styled.div`
  .form-spacer {
    margin-bottom: 1rem;
  }

  .form-title {
    margin-bottom: 1rem;
  }

  /* Component level styling -------------------------------------------------------------------- */

  .component-button-reset {
    font-size: 0.9rem;
    text-transform: none;
  }

  .component-button-signin {
    margin: ${(props) => props.theme.spacing(5, 0, 4)};
    padding: ${(props) => props.theme.spacing(2, 2)};
  }

  .component-caption {
    color: #9e9e9e;
    font-size: 2rem;
    margin: ${(props) => props.theme.spacing(5, 0, 5)};
  }

  .component-form {
    width: 100%;
  }

  .component-link {
    color: ${(props) => (props.theme.palette.mode === 'light' ? props.theme.palette.primary.dark : 'white')};
    font-size: 0.9rem;
    text-decoration: none;
  }

  .component-logo {
    background: ${(props) =>
      props.theme.palette.mode === 'light' ? `url('../assets/img/ap_logo.png') center/cover` : `url('../assets/img/ap_logo_dark.png') center/cover`};
    height: 65px;
    padding-bottom: ${(props) => props.theme.spacing(5)};
    width: 230px;
  }

  .component-paper {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.spacing(8, 4)};
  }

  /* Form input text styling -------------------------------------------------------------------- */

  .form-input-text {
    margin-bottom: 1rem;
  }

  /* Page level styling ------------------------------------------------------------------------- */

  .page-footer {
    bottom: 0;
    left: 2rem;
    margin: auto;
    position: absolute;
    top: 95vh;
  }

  .page-grid {
    height: 100vh;
  }

  .page-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(rgba(100, 100, 100, 0.6), rgba(100, 100, 100, 0.6)), url('../assets/img/sales-1.jpg') no-repeat center/cover fixed;
  }
`;
