// Material UI

import { Typography } from '@mui/material';

// Components and function

import Private from '../../pages/private/Private';

// Props

interface IDashboardProps {}

const Dashboard: React.FC<IDashboardProps> = () => {
  return (
    <Private>
      <Typography className='component-title' component='div' variant='h5'>
        Dashboard
      </Typography>
    </Private>
  );
};

export default Dashboard;
