// Dependencies

import styled from 'styled-components';

// Material UI

import { Button } from '@mui/material';

// Styles

const drawerWidth = 240;

export const Wrapper = styled.div`
  /* Component level styling -------------------------------------------------------------------- */

  .component-caption {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .component-divider {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .component-title {
    margin-top: 1rem;
  }

  /* Page level styling - appbar ---------------------------------------------------------------- */

  .page-appbar {
    z-index: ${(props) => props.theme.zIndex.drawer + 1};
  }

  /* Page level styling - content --------------------------------------------------------------- */

  .page-content {
    flex-grow: 1;
    padding: ${(props) => props.theme.spacing(3)};
    transition: ${(props) =>
      props.theme.transitions.create('margin', {
        duration: props.theme.transitions.duration.leavingScreen,
        easing: props.theme.transitions.easing.sharp,
      })};
    margin-left: ${-drawerWidth}px;
  }

  .page-content-appbar {
    ${(props) => ({ ...props.theme.mixins.toolbar })}
  }

  .page-content-shift {
    transition: ${(props) =>
      props.theme.transitions.create('margin', {
        duration: props.theme.transitions.duration.enteringScreen,
        easing: props.theme.transitions.easing.easeOut,
      })};
    margin-left: 0px;
  }

  /* Page level styling - drawer ---------------------------------------------------------------- */

  .page-drawer {
    ${(props) => props.theme.breakpoints.up('sm')} {
      flex-shrink: 0;
      width: ${drawerWidth}px;
    }
  }

  .page-drawer-appbar {
    ${(props) => props.theme.mixins.toolbar}
  }

  .page-drawer-image {
    padding-bottom: ${(props) => props.theme.spacing(1)};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-top: ${(props) => props.theme.spacing(2)};
    ${(props) => props.theme.breakpoints.down('sm')} {
      padding-top: ${(props) => props.theme.spacing(1)};
    }
    width: 60%;
  }

  .page-drawer-list-item-text {
    font-size: 0.9rem;
  }

  .page-drawer-menu-button {
    margin-right: ${(props) => props.theme.spacing(1)};
  }

  .page-drawer-nested {
    padding-left: ${(props) => props.theme.spacing(2)};
  }

  .page-drawer-paper {
    padding-top: ${(props) => props.theme.mixins.toolbar.minHeight}px;
    background: ${(props) => (props.theme.palette.mode === 'light' ? '#f7f7f7' : '#3a424a')};
    height: 100vh;
    width: ${drawerWidth}px;
  }

  /* Page level styling - grow ------------------------------------------------------------------ */

  .page-grow {
    flex-grow: 1;
  }

  /* Page level styling - menu ------------------------------------------------------------------ */

  .page-menu-button {
    margin-right: ${(props) => props.theme.spacing(1)};
  }

  .page-menu-section {
    display: none;
    ${(props) => props.theme.breakpoints.up('sm')} {
      display: flex;
    }
  }

  /* Page level styling - root ------------------------------------------------------------------ */

  .page-root {
    display: flex;
  }

  /* Page level styling - toolbar --------------------------------------------------------------- */

  .page-toolbar {
    background-color: ${(props) => (props.theme.palette.mode === 'light' ? props.theme.palette.primary : props.theme.palette.primary.dark)};
  }
`;

export const AboutAlertButton = styled(Button)`
  display: block;
  margin-bottom: 10px;
  min-width: 230px;
`;
