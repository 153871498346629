// Dependencies

import { MouseEvent, useState } from 'react';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// Material UI

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';

// Components and functions

import Messages, { handleMessageClick } from './Messages';
import ThemeToggle from '../../../styling/ThemeToggle';
import { auth } from '../../../firebase/firebase';
import { formSpinnerActive } from '../../../redux/formSpinner/formSpinner.slices';
import { getMessagesSelector } from '../../../redux/messages/messages.slice';
import { routeNames } from '../../../routes/Routes';
import { userClear } from '../../../redux/user/user.slices';

// Props

interface IDropDownItemsProps {
  isSmallScreen: boolean;
}

const DropDownItems: React.FC<IDropDownItemsProps> = ({ isSmallScreen }) => {
  const dispatch = useDispatch();
  const messages = useSelector(getMessagesSelector);
  const navigate = useNavigate();

  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null);
  const [systemAnchorEl, setSystemAnchorEl] = useState<null | HTMLElement>(null);

  const isProfileOpen = Boolean(profileAnchorEl);
  const isSystemOpen = Boolean(systemAnchorEl);

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = (link: routeNames) => {
    if (link) navigate(link);
    setProfileAnchorEl(null);
    handleSystemMenuClose();
  };

  const handleSystemMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setSystemAnchorEl(event.currentTarget);
  };

  const handleSystemMenuClose = () => {
    setSystemAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(formSpinnerActive(true));
    signOut(auth)
      .then(() => {
        dispatch(userClear());
        navigate('/');
        dispatch(formSpinnerActive(false));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(formSpinnerActive(false));
      });
  };

  const profileMenuId = 'profile-menu';

  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={profileMenuId}
      keepMounted
      onClose={handleProfileMenuClose}
      open={isProfileOpen}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem className={'page-dropdown-menuitem'} onClick={() => handleProfileMenuClose(routeNames.dashboard)}>
        Account details
      </MenuItem>
      <MenuItem className={'page-dropdown-menuitem'} onClick={() => handleProfileMenuClose(routeNames.dashboard)}>
        Change Password
      </MenuItem>
      <Divider />
      <MenuItem className={'page-dropdown-menuitem'} onClick={handleSignOut}>
        Sign out
      </MenuItem>
    </Menu>
  );

  const systemMenuId = 'system-menu';

  const renderSystemMenu = (
    <Menu
      anchorEl={systemAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={systemMenuId}
      keepMounted
      onClose={handleSystemMenuClose}
      open={isSystemOpen}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem className={'page-dropdown-menuitem'}>
        <ThemeToggle />
      </MenuItem>
      <MenuItem className={'page-dropdown-menuitem'} disabled={messages.total > 0 ? false : true}>
        <Messages />
        {messages.total > 0 ? <p onClick={() => handleMessageClick(messages.total)}>Messages</p> : <p>Messages</p>}
      </MenuItem>
      <Divider />
      <MenuItem className={'page-dropdown-menuitem'} onClick={handleProfileMenuOpen}>
        <IconButton aria-controls='page-dropdown-menuitem' aria-haspopup='true' aria-label='account of current user' color='inherit'>
          <AccountCircleIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {isSmallScreen ? (
        <IconButton aria-controls={systemMenuId} aria-haspopup='true' aria-label='show more' color='inherit' onClick={handleSystemMenuOpen}>
          <MoreVertIcon />
        </IconButton>
      ) : (
        <IconButton
          aria-controls={profileMenuId}
          aria-haspopup='true'
          aria-label='account of current user'
          color='inherit'
          edge='end'
          onClick={handleProfileMenuOpen}
        >
          <AccountCircleIcon />
        </IconButton>
      )}
      {renderSystemMenu}
      {renderProfileMenu}
    </>
  );
};

export default DropDownItems;
