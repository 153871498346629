// Dependencies

import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp, FirebaseApp } from 'firebase/app';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};

const Firebase: FirebaseApp = initializeApp(config);

export const auth = getAuth(Firebase);

export const Providers = {
  google: new GoogleAuthProvider(),
};

export default Firebase;
